import React, { useEffect, useState } from "react";
import { currencyByLang } from "../../constants";
import styles from "./index.module.css";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import CountrySelect from "../CountrySelect";

async function onApprove(data) {
  // replace this url with your server
  return await fetch(
    "https://sunsouls.deviceid.io:8888/api/paypal/capture-order",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderID: data.orderID,
      }),
    },
  )
    .then((response) => response.json())
    .then((orderData) => {
      // Your code here after capture the order
    });
}

const Checkout = (props) => {
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);
  const [mail, setMail] = useState("");
  const [mailValid, setMailValid] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameValid, setFirstNameValid] = useState(0);
  const [lastNameValid, setLastNameValid] = useState(0);
  const [phone, setPhone] = useState("");
  const [phoneValid, setPhoneValid] = useState(0);
  const [address, setAddress] = useState("");
  const [addressValid, setAddressValid] = useState(0);
  const [address2, setAddress2] = useState("");
  const [country, setCountry] = useState("");
  const [countryValid, setCountryValid] = useState(0);
  const [city, setCity] = useState("");
  const [cityValid, setCityValid] = useState(0);
  const [state, setState] = useState("");
  const [zip, setZIP] = useState("");
  const [zipValid, setZIPValid] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [shippingMehtod, setShippingMethod] = useState(0);
  const [promo, setPromo] = useState("");
  const [promoActive, setPromoActive] = useState(0);
  const [free, setFree] = useState(false); // free shipping
  const [code, setCode] = useState(972); // country code
  const [promoTotal, setPromoTotal] = useState(0); // total before promo

  const initialOptions = {
    currency: "ILS",
    components: "buttons",
    clientId:
      "AXIcJSLc-E_yKKB2v_S9p139JqsrgC28o38X5QaRXAd_AaVcXX7YiHMoSJMzuDwFCrzUDZl43LeHhz7o",
    //  clientId:
    //   "AVV84y78Yh93tIkC0AFS0045SjgPs4d4G8WHt7SGBNrfVRcGv0IrNy2eRHebb5zxFqJuPAMBribLDYTJ", // SANDBOX
  };

  useEffect(() => {
    const country = localStorage.getItem("sunsouls-country");
    if (country === 0) setCountry("USA");
    else if (country === 1) setCountry("ישראל");
  }, []);

  const checked_checkbox = (
    <div>
      <span className="hidden">
        <svg
          className="w-6 h-6 text-gray-300"
          viewBox="0 0 22 22"
          fill="none"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="11" cy="11" r="10.25" stroke-width="1.5" />
        </svg>
      </span>

      <span>
        <svg
          className="w-6 h-6"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="11" cy="11" r="11" fill="#18181B" />
          <path
            d="M6.91699 11.5833L9.25033 13.9166L15.0837 8.08331"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </div>
  );

  const unchecked_checkbox = (
    <div>
      <span>
        <svg
          className="w-6 h-6 text-gray-300"
          viewBox="0 0 22 22"
          fill="none"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="11" cy="11" r="10.25" stroke-width="1.5" />
        </svg>
      </span>

      <span className="hidden">
        <svg
          className="w-6 h-6"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="11" cy="11" r="11" fill="#18181B" />
          <path
            d="M6.91699 11.5833L9.25033 13.9166L15.0837 8.08331"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </div>
  );

  async function waitForResponse(win, tid) {
    if (!win.closed) {
      const response = await fetch(
        "https://sunsouls.deviceid.io/status?id=" + tid,
        {
          method: "GET",
          headers: {
            "Content-Type": "text/plain",
          },
        },
      );
      const data = await response.json();
      if (data.status) {
        setTotal(0);
        setCart([]);
        localStorage.removeItem("sunsouls-cart");
        win.close();
        /*
        props.setAlertType(true);
        props.setAlertText(
          props.lang === 0 ? "העסקה הושלמה בהצלחה" : "Transaction Completed !",
        );
        props.setShowAlert(true);
        setTimeout(() => props.setShowAlert(false), 3000);
         */
        window.location.replace("/thanks");
      } else setTimeout(() => waitForResponse(win, tid), 2000);
    }
  }

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("sunsouls-cart"));
    if (items === null || items === undefined || !Array.isArray(items)) return;
    setCart(items);
    var total = 0;
    var amount = 0;
    var promoTotal = 0;
    items.forEach((item) => {
      total += item.price * item.qty;
      promoTotal += (item.price - (promoActive === 1 ? 20 : 10)) * item.qty;
      amount += item.qty;
    });
    setTotal(total);
    setPromoTotal(promoTotal);
    if (amount >= 3) setFree(true);
    else setFree(false);
  }, [props.refresh, promoActive]);

  useEffect(() => {
    const name = localStorage.getItem("sunsouls-name");
    if (typeof name === "string" && name.includes(" ")) {
      const dt = name.split(" ");
      setFirstName(dt[0]);
      setLastName(dt[1]);
      if (dt[0].length > 1) setFirstNameValid(1);
      if (dt[1].length > 1) setLastNameValid(1);
    }
    const mail = localStorage.getItem("sunsouls-mail");
    if (
      typeof mail === "string" &&
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)
    ) {
      setMail(mail);
      setMailValid(1);
    }
    const phone = localStorage.getItem("sunsouls-phone");
    if (
      typeof phone === "string" &&
      phone != undefined &&
      phone.length > 7 &&
      /^[+]{1}(?:[0-9\-\\(\\)\\/.]\s?){6,15}[0-9]{1}$/.test(phone)
    ) {
      setPhone(phone);
      setPhoneValid(1);
    }
  }, []);

  return (
    <section
      className={`${styles.container} absolute top-[73px] py-12 bg-white sm:py-16 lg:py-20 w-full`}
    >
      <div className="px-4 mx-auto sm:px-6 lg:px-8">
        <div className="max-w-6xl mx-auto">
          <div>
            <h1
              className={`text-2xl font-bold text-gray-900 ${props.lang === 0 && "text-right"}`}
            >
              {props.lang === 1 ? "Checkout" : "תשלום"}
            </h1>
          </div>

          <div className="grid grid-cols-1 mt-8 lg:grid-cols-5 lg:items-start xl:grid-cols-6 gap-y-10 lg:gap-x-12 xl:gap-x-16">
            <div className="lg:sticky lg:order-2 lg:top-6 lg:col-span-2">
              <div className="overflow-hidden rounded bg-gray-50">
                <div className="px-4 py-6 sm:p-6 lg:p-8">
                  <h3
                    className={`text-xl font-bold text-gray-900 ${props.lang === 0 && "text-right"}`}
                  >
                    {props.lang === 1 ? "Order details" : "פירוט הזמנה"}
                  </h3>

                  <div className="flow-root mt-8">
                    <ul className="divide-y divide-gray-200 -my-7">
                      {cart.map((item) => (
                        <li className="flex items-stretch justify-between space-x-5 py-7">
                          <div className="flex-shrink-0">
                            <img
                              className="object-cover w-16 h-16 rounded-lg"
                              src={`data:image/${item.img.type};base64, ${item.img.data}`}
                              alt=""
                            />
                          </div>

                          <div className="flex flex-col justify-between flex-1 ml-5">
                            <div className="flex-1">
                              <p className="text-base font-bold text-gray-900">
                                {item.name[props.lang === 0 ? "HE" : "EN"]}
                              </p>
                              <p className="mt-1 text-sm font-medium text-gray-500">
                                {item.qty}
                              </p>
                              <p className="mt-1 text-sm font-medium text-gray-500">
                                {item.size === 0
                                  ? "S"
                                  : item.size === 1
                                    ? "M"
                                    : item.size === 2
                                      ? "L"
                                      : "XL"}
                              </p>
                            </div>
                            <p className="mt-2 text-sm font-bold text-gray-900 text-right relative bottom-10">
                              {item.price / (props.lang === 0 ? 1 : 3.5) -
                                (promoActive === 1
                                  ? props.lang === 0
                                    ? 20
                                    : 5.5
                                  : promoActive === 2
                                    ? props.lang === 0
                                      ? 10
                                      : 3
                                    : 0)}
                              {currencyByLang.get(
                                props.lang === 0 ? "HE" : "EN",
                              )}
                            </p>
                          </div>

                          <div className="ml-auto">
                            <button
                              type="button"
                              onClick={async () => {
                                const old_data = JSON.parse(
                                  localStorage.getItem("sunsouls-cart"),
                                );
                                var index = 0;
                                for (const dt of old_data) {
                                  if (dt.id === item.id) {
                                    old_data.splice(index, 1);
                                    console.log(old_data);
                                    break;
                                  }
                                  index++;
                                }
                                localStorage.setItem(
                                  "sunsouls-cart",
                                  JSON.stringify(old_data),
                                );
                                setCart(old_data);
                                props.setRefresh(!props.refresh);
                                if (
                                  localStorage.getItem("sunsouls-logged") ==
                                  true
                                ) {
                                  await fetch(
                                    "https://sunsouls.deviceid.io/cart?id=" +
                                      item.id,
                                    {
                                      method: "DELETE",
                                      headers: {
                                        "Content-Type": "text/plain",
                                        Authorization:
                                          "Bearer " +
                                          localStorage.getItem(
                                            "sunsouls-token",
                                          ),
                                      },
                                    },
                                  );
                                }
                                /*
                                if (
                                  localStorage.getItem("sunsouls-logged") ==
                                  true
                                ) {
                                  const response = await fetch(
                                    "https://sunsouls.deviceid.io/cart?id=" +
                                      item.id,
                                    {
                                      method: "DELETE",
                                      headers: {
                                        "Content-Type": "text/plain",
                                        Authorization:
                                          "Bearer " +
                                          localStorage.getItem(
                                            "sunsouls-token",
                                          ),
                                      },
                                    },
                                  );
                                  if (response.status === 200) {
                                    const items = JSON.parse(
                                      localStorage.getItem("sunsouls-cart"),
                                    );
                                    if (
                                      items === null ||
                                      items === undefined ||
                                      !Array.isArray(items)
                                    )
                                      return;
                                    if (items.length === 1) {
                                      setCart([]);
                                      localStorage.setItem(
                                        "sunsouls-cart",
                                        JSON.stringify([]),
                                      );
                                    } else {
                                      var index = 0;
                                      for (const itm of items) {
                                        if (itm.id === item.id) {
                                          items.slice(index, 1);
                                          break;
                                        }
                                        index++;
                                      }
                                      setCart(items);
                                      props.setRefresh(!props.refresh);
                                      localStorage.setItem(
                                        "sunsouls-cart",
                                        JSON.stringify(items),
                                      );
                                    }
                                  } else {
                                  }
                                */
                              }}
                              className="inline-flex p-2 -m-2 text-gray-400 transition-all duration-200 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:text-gray-900"
                            >
                              <svg
                                className="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <hr className="mt-6 border-gray-200" />

                  <div className="flow-root mt-5">
                    <div className="-my-5 divide-y divide-gray-200">
                      <div className="flex items-center justify-between py-5">
                        <p className="text-sm font-medium text-gray-600">
                          {props.lang === 1 ? "Subtotal" : "סכום בניים"}
                        </p>
                        <p className="text-sm font-medium text-right text-gray-600">
                          {(total / (props.lang === 0 ? 1 : 3.5)).toFixed(2)}
                          {currencyByLang.get(props.lang === 0 ? "HE" : "EN")}
                        </p>
                      </div>

                      {/* <div className="flex items-center justify-between py-5">
                        <p className="text-sm font-medium text-gray-600">
                          {props.lang === 1 ? "Tax" : "מס"}
                        </p>
                        <p className="text-sm font-medium text-right text-gray-600">
                          0{currencyByLang[props.lang === 0 ? "HE" : "EN"]}
                        </p>
                      </div> */}

                      {promoActive !== 0 && (
                        <div className="flex items-center justify-between py-5">
                          <p className="text-sm font-medium text-gray-600">
                            {props.lang === 1 ? "Promo Discount" : "הנחת פרומו"}
                          </p>
                          <p className="text-sm font-medium text-right text-gray-600">
                            {promoActive === 1 ? "-10%" : "15%"}
                          </p>
                        </div>
                      )}

                      <div className="flex items-center justify-between py-5">
                        <p className="text-sm font-medium text-gray-600">
                          {props.lang === 1 ? "Shipping" : "משלוח"}
                        </p>
                        <p className="text-sm font-medium text-right text-gray-600">
                          {(
                            (free
                              ? 0
                              : shippingMehtod === 0
                                ? 0
                                : shippingMehtod === 1
                                  ? 18
                                  : 35) / (props.lang === 0 ? 1 : 3.5)
                          ).toFixed(2)}

                          {currencyByLang.get(props.lang === 0 ? "HE" : "EN")}
                        </p>
                      </div>

                      <div className="flex items-center justify-between py-5">
                        <p className="text-sm font-bold text-gray-900">
                          {props.lang === 1 ? "Total" : "סכום סופי"}
                        </p>
                        <p
                          key={promoActive}
                          className="text-sm font-bold text-right text-gray-900"
                        >
                          {(
                            (free
                              ? promoActive !== 0
                                ? promoTotal
                                : total
                              : (shippingMehtod === 1
                                  ? 18
                                  : shippingMehtod === 2
                                    ? 35
                                    : 0) +
                                (promoActive !== 0 ? promoTotal : total)) /
                            (props.lang === 0 ? 1 : 3.5)
                          ).toFixed(2)}
                          {currencyByLang.get(props.lang === 0 ? "HE" : "EN")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full h-[50px] mt-[15px] flex items-center justify-center gap-5">
                    <input
                      type="text"
                      value={promo}
                      onChange={(e) => setPromo(e.target.value)}
                      className="flex-2 border block w-full py-2 pl-10 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                      placeholder="PROMO CODE"
                    />
                    <button
                      className="flex-1 inline-flex items-center justify-center w-full px-6 py-2 text-sm font-bold text-white transition-all duration-200 bg-[#d6b47c] border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-700"
                      onClick={() => {
                        if (promo === "FNF24") {
                          setPromoActive(1);
                          setPromo("");
                          props.setAlertType(true);
                          props.setAlertText(
                            props.lang === 0
                              ? "הקוד הופעל בהצלחה"
                              : "Promo code successfully applied",
                          );
                          props.setShowAlert(true);
                          setTimeout(() => props.setShowAlert(false), 3000);
                        } else {
                          props.setAlertType(false);
                          props.setAlertText(
                            props.lang === 0
                              ? "הכניסו קוד תקין"
                              : "Please enter a valid promo code",
                          );
                          props.setShowAlert(true);
                          setTimeout(() => props.setShowAlert(false), 3000);
                        }
                      }}
                    >
                      {props.lang === 1 ? "Apply" : "החל"}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-6 border-t border-gray-200 lg:order-1 lg:col-span-3 xl:col-span-4">
              <div className={`py-7 ${props.lang === 0 && "text-right"}`}>
                <div className="mt-6 space-y-4">
                  <div
                    className={`bg-white border-2 ${!paymentMethod ? "border-gray-900" : "border-gray-200"} rounded-md cursor-pointer`}
                    onClick={() => setPaymentMethod(false)}
                  >
                    <div
                      className={`px-4 py-5 sm:p-6 ${props.lang === 0 && "float-right"}`}
                    >
                      <div className="flex items-center">
                        {props.lang === 1 &&
                          (!paymentMethod
                            ? checked_checkbox
                            : unchecked_checkbox)}

                        <div className="ml-4">
                          <p
                            className={`text-base font-bold text-gray-900 ${props.lang === 0 && "pr-5"}`}
                          >
                            {props.lang === 1 ? "Credit Card" : "כרטיס אשראי"}
                          </p>
                          <p
                            className={`mt-1 text-sm font-medium text-gray-500 ${props.lang === 0 && "pr-5"}`}
                          >
                            {props.lang === 1
                              ? "Visa, Mastercard, American Amex"
                              : "ויזה, מאסטרכארד, אמאקס"}
                          </p>
                        </div>
                        {props.lang === 0 &&
                          (!paymentMethod
                            ? checked_checkbox
                            : unchecked_checkbox)}
                      </div>
                      {/*
                        <div className="grid grid-cols-2 mt-5 sm:grid-cols-4 gap-x-6 gap-y-5">
                          <div className="col-span-2 sm:col-span-4">
                            <label
                              for=""
                              className="text-sm font-medium text-gray-600"
                            >
                              {" "}
                              Card number{" "}
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name=""
                                id=""
                                placeholder="XXXX XXXX XXXX XXXX"
                                className="block w-full px-4 py-3 text-sm font-normal text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md caret-gray-900 focus:ring-gray-900 focus:border-gray-900"
                              />
                            </div>
                          </div>

                          <div className="col-span-2">
                            <label
                              for=""
                              className="text-sm font-medium text-gray-600"
                            >
                              {" "}
                              Name on the card{" "}
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name=""
                                id=""
                                placeholder="ex: John Doe"
                                className="block w-full px-4 py-3 text-sm font-normal text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md caret-gray-900 focus:ring-gray-900 focus:border-gray-900"
                              />
                            </div>
                          </div>

                          <div>
                            <label
                              for=""
                              className="text-sm font-medium text-gray-600"
                            >
                              {" "}
                              Expiry date{" "}
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name=""
                                id=""
                                placeholder="MM/YYYY"
                                className="block w-full px-4 py-3 text-sm font-normal text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md caret-gray-900 focus:ring-gray-900 focus:border-gray-900"
                              />
                            </div>
                          </div>

                          <div>
                            <label
                              for=""
                              className="text-sm font-medium text-gray-600"
                            >
                              {" "}
                              CSV{" "}
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name=""
                                id=""
                                placeholder="XXX"
                                className="block w-full px-4 py-3 text-sm font-normal text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md caret-gray-900 focus:ring-gray-900 focus:border-gray-900"
                              />
                            </div>

                          </div>

                        </div>
                        */}
                    </div>
                  </div>

                  <div
                    className={`bg-white border-2 ${paymentMethod ? "border-gray-900" : "border-gray-200"} rounded-md cursor-pointer`}
                    onClick={() => setPaymentMethod(true)}
                  >
                    <div
                      className={`px-4 py-5 sm:p-6 ${props.lang === 0 && "float-right"}`}
                    >
                      <div className="flex items-center">
                        {props.lang === 1 &&
                          (paymentMethod
                            ? checked_checkbox
                            : unchecked_checkbox)}
                        <div className="ml-4">
                          <p
                            className={`text-base font-bold text-gray-900 ${props.lang === 0 && "pr-5"}`}
                          >
                            PayPal
                          </p>
                          <p
                            className={`mt-1 text-sm font-medium text-gray-500 ${props.lang === 0 && "pr-5"}`}
                          >
                            {props.lang === 1
                              ? "One click PayPal payment"
                              : "תשלום מהיר באמצעות PayPal"}
                          </p>
                        </div>
                        {props.lang === 0 &&
                          (paymentMethod
                            ? checked_checkbox
                            : unchecked_checkbox)}
                      </div>
                    </div>
                    ֿ
                  </div>
                </div>
              </div>

              <div className="flow-root">
                <div className="divide-y divide-gray-200 -my-7">
                  {!paymentMethod && (
                    <>
                      <div className="py-7">
                        <h2
                          className={`text-base font-bold text-gray-900 ${props.lang === 0 && "text-right"}`}
                        >
                          {props.lang === 1
                            ? "Contact Information"
                            : "פרטי התקשרות"}
                        </h2>

                        <div
                          className={`mt-6 ${props.lang === 0 && "text-right"}`}
                        >
                          <label
                            for=""
                            className="text-sm font-medium text-gray-600"
                          >
                            {" "}
                            {props.lang === 1
                              ? "Email address"
                              : "כתובת איימיל"}{" "}
                          </label>
                          <div className="mt-2">
                            <input
                              type="email"
                              placeholder="mail@mail.com"
                              value={mail}
                              onChange={(e) => {
                                setMailValid(0);
                                if (e.target.value.length > 0) {
                                  setMail(e.target.value);
                                  if (
                                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                      e.target.value,
                                    )
                                  )
                                    setMailValid(1);
                                  else setMailValid(2);
                                } else setMail("");
                              }}
                              className={`block w-full px-4 py-3 text-sm font-normal text-gray-900 placeholder-gray-500 bg-white border ${mailValid === 0 ? "border-gray-300" : mailValid === 1 ? "border-green-300" : "border-red-300"} rounded-md caret-gray-900 focus:ring-gray-900 focus:border-gray-900`}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="py-7">
                        <h2
                          className={`text-base font-bold text-gray-900 ${props.lang === 0 && "text-right"}`}
                        >
                          {props.lang === 1
                            ? "Shipping Information"
                            : "פרטי משלוח"}
                        </h2>

                        <div className="grid grid-cols-1 mt-6 sm:grid-cols-2 gap-y-5 gap-x-6">
                          <div className={props.lang === 0 ? "text-right" : ""}>
                            <label
                              for=""
                              className="text-sm font-medium text-gray-600"
                            >
                              {" "}
                              {props.lang === 1 ? "First name" : "שם פרטי"}{" "}
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                id=""
                                name=""
                                placeholder=""
                                value={firstName}
                                onChange={(e) => {
                                  setFirstName(e.target.value);
                                  setFirstNameValid(0);
                                  if (
                                    e.target.value.length < 2 ||
                                    e.target.value.length > 255
                                  ) {
                                    if (e.target.value.length !== 0)
                                      setFirstNameValid(2);
                                  } else setFirstNameValid(1);
                                }}
                                className={`block w-full px-4 py-3 text-sm font-normal text-gray-900 placeholder-gray-500 bg-white border ${firstNameValid === 0 ? "border-gray-300" : firstNameValid === 1 ? "border-green-300" : "border-red-300"} rounded-md caret-gray-900 focus:ring-gray-900 focus:border-gray-900`}
                              />
                            </div>
                          </div>

                          <div className={props.lang === 0 ? "text-right" : ""}>
                            <label
                              for=""
                              className="text-sm font-medium text-gray-600"
                            >
                              {" "}
                              {props.lang === 1 ? "Last name" : "שם משפחה"}{" "}
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                id=""
                                name=""
                                placeholder=""
                                value={lastName}
                                onChange={(e) => {
                                  setLastName(e.target.value);
                                  setLastNameValid(0);
                                  if (
                                    e.target.value.length < 2 ||
                                    e.target.value.length > 255
                                  ) {
                                    if (e.target.value.length !== 0)
                                      setLastNameValid(2);
                                  } else setLastNameValid(1);
                                }}
                                className={`block w-full px-4 py-3 text-sm font-normal text-gray-900 placeholder-gray-500 bg-white border ${lastNameValid === 0 ? "border-gray-300" : lastNameValid === 1 ? "border-green-300" : "border-red-300"} rounded-md caret-gray-900 focus:ring-gray-900 focus:border-gray-900`}
                              />
                            </div>
                          </div>

                          <div
                            className={`sm:col-span-2 ${props.lang === 0 && "text-right"}`}
                          >
                            <label
                              for=""
                              className="text-sm font-medium text-gray-600"
                            >
                              {" "}
                              {props.lang === 1
                                ? "Phone number"
                                : "מספר טלפון"}{" "}
                            </label>
                            <div className="mt-2 flex items-center justify-evenly gap-5">
                              <CountrySelect setTarget={setCode} />
                              <input
                                type="text"
                                id=""
                                name=""
                                value={phone}
                                onChange={(e) => {
                                  setPhone(e.target.value);
                                  setPhoneValid(0);
                                  if (
                                    /^(?:[0-9\-\\(\\)\\/.]\s?){6,15}[0-9]{1}$/.test(
                                      e.target.value,
                                    )
                                  ) {
                                    setPhoneValid(1);
                                  } else {
                                    setPhoneValid(2);
                                  }
                                }}
                                placeholder="(555) 555-1234"
                                className={`block border-gray-300 w-full px-4 py-3 text-sm font-normal text-gray-900 placeholder-gray-500 bg-white border ${phoneValid === 0 ? "border-gray-300" : phoneValid === 1 ? "border-green-300" : "border-red-300"} rounded-md caret-gray-900 focus:ring-gray-900 focus:border-gray-900`}
                              />
                            </div>
                          </div>

                          {shippingMehtod !== 0 && (
                            <>
                              <div
                                className={`sm:col-span-2 ${props.lang === 0 && "text-right"}`}
                              >
                                <label
                                  for=""
                                  className="text-sm font-medium text-gray-600"
                                >
                                  {" "}
                                  {props.lang === 1
                                    ? "Address line 1"
                                    : "כתובת ראשית"}{" "}
                                </label>
                                <div className="mt-2">
                                  <input
                                    type="text"
                                    id=""
                                    name=""
                                    value={address}
                                    onChange={(e) => {
                                      setAddress(e.target.value);
                                      setAddressValid(0);
                                      if (
                                        e.target.value.length < 5 ||
                                        e.target.value.length > 255
                                      ) {
                                        if (e.target.value.length !== 0)
                                          setAddressValid(2);
                                      } else setAddressValid(1);
                                    }}
                                    placeholder=""
                                    className={`block w-full px-4 py-3 text-sm font-normal text-gray-900 placeholder-gray-500 bg-white border ${addressValid === 0 ? "border-gray-300" : addressValid === 1 ? "border-green-300" : "border-red-300"} rounded-md caret-gray-900 focus:ring-gray-900 focus:border-gray-900`}
                                  />
                                </div>
                              </div>

                              <div
                                className={`sm:col-span-2 ${props.lang === 0 && "text-right"}`}
                              >
                                <label
                                  for=""
                                  className="text-sm font-medium text-gray-600"
                                >
                                  {" "}
                                  {props.lang === 1
                                    ? "Address line 2"
                                    : "כתובת משנית"}{" "}
                                </label>
                                <div className="mt-2">
                                  <input
                                    type="text"
                                    id=""
                                    name=""
                                    placeholder=""
                                    value={address2}
                                    onChange={(e) =>
                                      e.target.value.length <= 255 &&
                                      setAddress2(e.target.value)
                                    }
                                    className="block w-full px-4 py-3 text-sm font-normal text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md caret-gray-900 focus:ring-gray-900 focus:border-gray-900"
                                  />
                                </div>
                              </div>

                              <div
                                className={props.lang === 0 ? "text-right" : ""}
                              >
                                <label
                                  for=""
                                  className="text-sm font-medium text-gray-600"
                                >
                                  {" "}
                                  {props.lang === 1 ? "Country" : "ארץ"}{" "}
                                </label>
                                <div className="mt-2">
                                  <input
                                    type="text"
                                    id=""
                                    name=""
                                    value={country}
                                    onChange={(e) => {
                                      setCountry(e.target.value);
                                      setCountryValid(0);
                                      if (
                                        e.target.value.length < 4 ||
                                        e.target.value.length > 56
                                      ) {
                                        if (e.target.value.length !== 0)
                                          setCountryValid(2);
                                      } else setCountryValid(1);
                                    }}
                                    placeholder=""
                                    className={`block w-full px-4 py-3 text-sm font-normal text-gray-900 placeholder-gray-500 bg-white border ${countryValid === 0 ? "border-gray-300" : countryValid === 1 ? "border-green-300" : "border-red-300"} rounded-md caret-gray-900 focus:ring-gray-900 focus:border-gray-900`}
                                  />
                                </div>
                              </div>

                              <div
                                className={props.lang === 0 ? "text-right" : ""}
                              >
                                <label
                                  for=""
                                  className="text-sm font-medium text-gray-600"
                                >
                                  {" "}
                                  {props.lang === 1 ? "City" : "עיר"}{" "}
                                </label>
                                <div className="mt-2">
                                  <input
                                    type="text"
                                    id=""
                                    name=""
                                    placeholder=""
                                    value={city}
                                    onChange={(e) => {
                                      setCity(e.target.value);
                                      setCityValid(0);
                                      if (
                                        e.target.value.length >= 0 &&
                                        e.target.value.length < 200
                                      ) {
                                        setCityValid(1);
                                      } else setCityValid(2);
                                    }}
                                    className={`block w-full px-4 py-3 text-sm font-normal text-gray-900 placeholder-gray-500 bg-white border ${cityValid === 0 ? "border-gray-300" : cityValid === 1 ? "border-green-300" : "border-red-300"} rounded-md caret-gray-900 focus:ring-gray-900 focus:border-gray-900`}
                                  />
                                </div>
                              </div>

                              <div
                                className={props.lang === 0 ? "text-right" : ""}
                              >
                                <label
                                  for=""
                                  className="text-sm font-medium text-gray-600"
                                >
                                  {" "}
                                  {props.lang === 1 ? "State" : "מחוז"}{" "}
                                </label>
                                <div className="mt-2">
                                  <input
                                    type="text"
                                    id=""
                                    name=""
                                    value={state}
                                    onChange={(e) => {
                                      setState(e.target.value);
                                    }}
                                    placeholder=""
                                    className={`block w-full px-4 py-3 text-sm font-normal text-gray-900 placeholder-gray-500 bg-white border ${state.length === 0 ? "border-gray-300" : state.length < 24 ? "border-green-300" : "border-red-300"} rounded-md caret-gray-900 focus:ring-gray-900 focus:border-gray-900`}
                                  />
                                </div>
                              </div>

                              <div
                                className={props.lang === 0 ? "text-right" : ""}
                              >
                                <label
                                  for=""
                                  className="text-sm font-medium text-gray-600"
                                >
                                  {" "}
                                  {props.lang === 1
                                    ? "Postal code"
                                    : "מיקוד"}{" "}
                                </label>
                                <div className="mt-2">
                                  <input
                                    type="text"
                                    id=""
                                    name=""
                                    value={zip}
                                    onChange={(e) => {
                                      setZIP(e.target.value);
                                      setZIPValid(0);
                                      if (
                                        e.target.value.length >= 0 &&
                                        e.target.value.length < 200
                                      ) {
                                        setZIPValid(1);
                                      } else setZIPValid(2);
                                    }}
                                    placeholder=""
                                    className={`block w-full px-4 py-3 text-sm font-normal text-gray-900 placeholder-gray-500 bg-white border ${zipValid === 0 ? "border-gray-300" : zipValid === 1 ? "border-green-300" : "border-red-300"} rounded-md caret-gray-900 focus:ring-gray-900 focus:border-gray-900`}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <div className={`py-7 ${props.lang === 0 && "text-right"}`}>
                    <h2 className="text-base font-bold text-gray-900">
                      {props.lang === 1 ? "Shipping" : "משלוח"}
                    </h2>
                    <br />
                    <div
                      className={`bg-white border-2 ${shippingMehtod === 3 ? "border-gray-900" : "border-gray-200"}  rounded-md cursor-pointer`}
                      onClick={() => setShippingMethod(0)}
                    >
                      <div
                        className={`px-4 py-5 sm:p-6 ${props.lang === 0 && "float-right"}`}
                      >
                        <div className="flex items-center">
                          {props.lang === 1 &&
                            (shippingMehtod === 0
                              ? checked_checkbox
                              : unchecked_checkbox)}

                          <div className="ml-4">
                            <p
                              className={`text-base font-bold text-gray-900 ${props.lang === 0 && "pr-5"}`}
                            >
                              {props.lang === 1 ? "Self Pick Up" : "איסוף עצמי"}
                            </p>
                            <p
                              className={`mt-1 text-sm font-medium text-gray-500 ${props.lang === 0 && "pr-5"}`}
                            >
                              {props.lang === 1
                                ? "Pick the item yourself from our physical location"
                                : "איסוף עצמי מרחובות או מעלות תרשיחא (תקבלו הודעה כשההזמנה מוכנה)"}
                            </p>
                          </div>
                          {props.lang === 0 &&
                            (shippingMehtod === 0
                              ? checked_checkbox
                              : unchecked_checkbox)}
                        </div>
                      </div>
                    </div>
                    <br />

                    <div
                      className={`bg-white border-2 ${shippingMehtod === 1 ? "border-gray-900" : "border-gray-200"} rounded-md cursor-pointer`}
                      onClick={() => setShippingMethod(1)}
                    >
                      <div
                        className={`px-4 py-5 sm:p-6 ${props.lang === 0 && "float-right"}`}
                      >
                        <div className="flex items-center">
                          {props.lang === 1 &&
                            (shippingMehtod === 1
                              ? checked_checkbox
                              : unchecked_checkbox)}
                          <div className="ml-4">
                            <p
                              className={`text-base font-bold text-gray-900 ${props.lang === 0 && "pr-5"}`}
                            >
                              {props.lang === 1
                                ? "Express Mail"
                                : "משלוח אקספרס"}
                            </p>
                            <p
                              className={`mt-1 text-sm font-medium text-gray-500  ${props.lang === 0 && "pr-5"}`}
                            >
                              {props.lang === 1
                                ? "International express air shipping"
                                : "משלוח אקספרס בהפצה לנקודות איסוף (עד שבעה ימי עסקים)"}
                            </p>
                          </div>
                          {props.lang === 0 &&
                            (shippingMehtod === 1
                              ? checked_checkbox
                              : unchecked_checkbox)}
                        </div>
                      </div>
                    </div>

                    <br />

                    <div
                      className={`bg-white border-2 ${shippingMehtod === 2 ? "border-gray-900" : "border-gray-200"} rounded-md cursor-pointer`}
                      onClick={() => setShippingMethod(2)}
                    >
                      <div
                        className={`px-4 py-5 sm:p-6 ${props.lang === 0 && "float-right"}`}
                      >
                        <div className="flex items-center">
                          {props.lang === 1 &&
                            (shippingMehtod === 2
                              ? checked_checkbox
                              : unchecked_checkbox)}

                          <div className="ml-4">
                            <p
                              className={`text-base font-bold text-gray-900 ${props.lang === 0 && "pr-5"}`}
                            >
                              {props.lang === 1
                                ? "Door To Door Shipping"
                                : "משלוח לבית הלקוח"}
                            </p>
                            <p
                              className={`mt-1 text-sm font-medium text-gray-500 ${props.lang === 0 && "pr-5"}`}
                            >
                              {props.lang === 1
                                ? "The fastest shipping method delivered to your address (Domestic Only)"
                                : "משלוח ישירות לבית הלקוח, אופציית השילוח המהירה ביותר (זמין בארץ בלבד)"}
                            </p>
                          </div>
                          {props.lang === 0 &&
                            (shippingMehtod === 2
                              ? checked_checkbox
                              : unchecked_checkbox)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 w-full flex items-center justify-center">
        {paymentMethod && (
          <PayPalScriptProvider key={shippingMehtod} options={initialOptions}>
            <PayPalButtons
              key={promoActive}
              style={{
                shape: "rect",
                layout: "vertical",
                color: "gold",
                label: "paypal",
              }}
              createOrder={async () => {
                console.log(cart);
                const items = cart.map((item) => ({
                  id: item.id,
                  quantity: item.qty,
                  price: item.price,
                }));
                try {
                  const response = await fetch(
                    "https://sunsouls.deviceid.io:8888/api/orders",
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      // use the "body" param to optionally pass additional order information
                      // like product ids and quantities
                      body: JSON.stringify({
                        shipping: shippingMehtod,
                        cart: items,
                        promo: promoActive,
                      }),
                    },
                  );

                  const orderData = await response.json();

                  if (orderData.id) {
                    return orderData.id;
                  } else {
                    const errorDetail = orderData?.details?.[0];
                    const errorMessage = errorDetail
                      ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                      : JSON.stringify(orderData);

                    throw new Error(errorMessage);
                  }
                } catch (error) {
                  console.error(error);
                  console.log(`Could not initiate PayPal Checkout...${error}`);
                }
              }}
              onApprove={async (data, actions) => {
                const items = cart.map((item) => ({
                  id: item.id,
                  quantity: item.qty,
                  price: item.price,
                  size: item.size,
                  name:
                    item.name.EN +
                    " (" +
                    (item.size === 0
                      ? "S"
                      : item.size === 1
                        ? "M"
                        : item.size === 2
                          ? "L"
                          : "XL") +
                    ")",
                }));
                try {
                  const response = await fetch(
                    `https://sunsouls.deviceid.io:8888/api/orders/${data.orderID}/capture`,
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        items,
                        shipping: shippingMehtod,
                        promo: promoActive,
                        token: localStorage.getItem("sunsouls-token"),
                      }),
                    },
                  );

                  const orderData = await response.json();
                  // Three cases to handle:
                  //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                  //   (2) Other non-recoverable errors -> Show a failure message
                  //   (3) Successful transaction -> Show confirmation or thank you message

                  const errorDetail = orderData?.details?.[0];

                  if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                    // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                    // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                    return actions.restart();
                  } else if (errorDetail) {
                    // (2) Other non-recoverable errors -> Show a failure message
                    throw new Error(
                      `${errorDetail.description} (${orderData.debug_id})`,
                    );
                  } else {
                    setCart([]);
                    setTotal(0);
                    localStorage.removeItem("sunsouls-cart");
                    props.setRefresh(!props.refresh);
                    props.setAlertText(
                      props.lang === 0 ? "ההזמנה הושלמה " : "Order Completed !",
                    );
                    props.setAlertType(true);
                    props.setShowAlert(true);
                    setTimeout(() => props.setShowAlert(false), 3000);
                    /*
                      const transaction =
                        orderData.purchase_units[0].payments
                          .captures[0];
                      console.log(
                        `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`,
                      );
                      console.log(
                        "Capture result",
                        orderData,
                        JSON.stringify(orderData, null, 2),
                      );
                       */
                  }
                } catch (error) {
                  console.error(error);
                  console.log(
                    `Sorry, your transaction could not be processed...${error}`,
                  );
                }
              }}
            />
          </PayPalScriptProvider>
        )}
        {!paymentMethod && (
          <button
            type="button"
            onClick={async (e) => {
              e.preventDefault();

              if (mailValid !== 1) {
                props.setAlertType(false);
                props.setAlertText(
                  props.lang === 0
                    ? "הכניסו איימיל תקין"
                    : "Please enter a valid email address",
                );
                props.setShowAlert(true);
                setTimeout(() => props.setShowAlert(false), 3000);
                return;
              }
              if (firstNameValid !== 1) {
                props.setAlertType(false);
                props.setAlertText(
                  props.lang === 0
                    ? "הכניסו שם פרטי תקין"
                    : "Please enter a valid first name",
                );
                props.setShowAlert(true);
                setTimeout(() => props.setShowAlert(false), 3000);
                return;
              }
              if (lastNameValid !== 1) {
                props.setAlertType(false);
                props.setAlertText(
                  props.lang === 0
                    ? "הכניסו שם משפחה תקין"
                    : "Please enter a valid last name",
                );
                props.setShowAlert(true);
                setTimeout(() => props.setShowAlert(false), 3000);
                return;
              }
              if (phoneValid !== 1) {
                props.setAlertType(false);
                props.setAlertText(
                  props.lang === 0
                    ? "הכניסו מספר טלפון תקין"
                    : "Please enter a valid phone number",
                );
                props.setShowAlert(true);
                setTimeout(() => props.setShowAlert(false), 3000);
                return;
              }
              if (shippingMehtod !== 0) {
                if (addressValid !== 1) {
                  props.setAlertType(false);
                  props.setAlertText(
                    props.lang === 0
                      ? "הכניסו כתובת תקינה"
                      : "Please enter a valid address",
                  );
                  props.setShowAlert(true);
                  setTimeout(() => props.setShowAlert(false), 3000);
                  return;
                }
                if (countryValid !== 1) {
                  props.setAlertType(false);
                  props.setAlertText(
                    props.lang === 0
                      ? "הכניסו ארץ תקינה"
                      : "Please enter a valid country",
                  );
                  props.setShowAlert(true);
                  setTimeout(() => props.setShowAlert(false), 3000);
                  return;
                }
                if (cityValid !== 1) {
                  props.setAlertType(false);
                  props.setAlertText(
                    props.lang === 0
                      ? "הכניסו עיר תקינה"
                      : "Please enter a valid city",
                  );
                  props.setShowAlert(true);
                  setTimeout(() => props.setShowAlert(false), 3000);
                  return;
                }
                if (zipValid !== 1) {
                  props.setAlertType(false);
                  props.setAlertText(
                    props.lang === 0
                      ? "הכניסו מיקוד תקין"
                      : "Please enter a valid postal code",
                  );
                  props.setShowAlert(true);
                  setTimeout(() => props.setShowAlert(false), 3000);
                  return;
                }
              }
              const logged = localStorage.getItem("sunsouls-logged");
              var url = "https://sunsouls.deviceid.io/createLink";
              const items = cart.map((item) => ({
                id: item.id,
                name:
                  item.name.EN +
                  " (" +
                  (item.size === 0
                    ? "S"
                    : item.size === 1
                      ? "M"
                      : item.size === 2
                        ? "L"
                        : "XL") +
                  ")",
                quantity: item.qty,
                price: item.price,
                discount_type: "amount",
                discount_value:
                  promoActive === 1
                    ? 20 * item.qty
                    : promoActive === 2
                      ? 10 * item.qty
                      : 0,
                vat_type: 0,
              }));
              const response = await fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "text/plain",
                  Authorization:
                    "Bearer " + localStorage.getItem("sunsouls-token"),
                },
                body: JSON.stringify({
                  name: firstName + " " + lastName,
                  email: mail,
                  phone: `+${code}${phone}`,
                  vat: "",
                  items,
                  payments: 1,
                  promo: promoActive,
                  shipping: shippingMehtod,
                  shipping_info:
                    address +
                    ", " +
                    address2 +
                    " " +
                    zip +
                    ", " +
                    country +
                    ", " +
                    city,
                }),
              });
              if (!response.ok) {
                // Error
                return;
              }
              const content = await response.json();
              setTimeout(() => {
                const win = window.open(
                  content.url,
                  "test",
                  `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=700, ${props.width > 768 ? "left=0, top=0" : "left=100, top=100"}`,
                );
                waitForResponse(win, content.id);
              });
            }}
            className="inline-flex items-center justify-center w-[80%] px-6 py-4 text-sm font-bold text-white transition-all duration-200 bg-[#d6b47c] border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-700"
          >
            {props.lang === 1 ? "Confirm payment" : "אישור תשלום"}
          </button>
        )}
      </div>
    </section>
  );
};

export default Checkout;
